import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';

import '../../App.scss';

import EnterEmailSurface from '../../components/EnterEmailSurface';
import { useTranslation } from 'react-i18next';

const EnterEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateBack = () => {
    navigate(APP_ROUTES.PHOTO_STYLES_INSTRUCTION);
  };

  return (
    <EnterEmailSurface
      onBack={navigateBack}
      text={t('youAreAlmostThere!EnterEmail')}
    />
  );
};

export default EnterEmail;
