import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PhotoContext } from '../context/PhotoProvider';
import { ProductTypes } from '../types/enums';
import routesConfig from './routesConfig';

const Router = () => {
  const location = useLocation();
  const { productType, setProductType } = useContext(PhotoContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    Object.values(ProductTypes)
      .filter((value) => value !== ProductTypes.MOSAIC)
      .forEach((value) => {
        if (
          value === ProductTypes.PAINTING &&
          location.pathname.includes(ProductTypes.DOT_PAINTING)
        ) {
          return;
        }
        if (location.pathname.includes(value) && productType !== value) {
          setProductType(value);
        }
      });
  }, [location]);
  return (
    <Routes>
      {routesConfig.map((route) => {
        const Page = route.element;
        return <Route key={route.path} path={route.path} element={<Page />} />;
      })}
    </Routes>
  );
};

export default Router;
