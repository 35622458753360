import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PhotoEditor from '../../components/PhotoEditor';
import { API_ROUTES } from '../../constants/api_routes';
import { APP_ROUTES } from '../../constants/appRoutes';
import { STYLES_ARR } from '../../constants/stylesArr';
import { PhotoContext } from '../../context/PhotoProvider';
import { useProductType } from '../../hooks/useProductType';
import api from '../../services/api';
import { ProductTypes } from '../../types/enums';

import styles from './styles.module.scss';

const apiRoutesMap = {
  [ProductTypes.MOSAIC]: {
    uploadApi: API_ROUTES.UPLOAD_PHOTO,
    getOneGoodImg: API_ROUTES.GET_ONE_GOOD_IMG,
    navigateOnreload: APP_ROUTES.CHOOSE_SIZE_DIAMOND_PHOTO_MOSAIC,
    photoStyle: APP_ROUTES.PHOTO_STYLE,
    uploadPhoto: APP_ROUTES.UPLOAD_PHOTO,
  },
  [ProductTypes.PAINTING]: {
    uploadApi: API_ROUTES.UPLOAD_PAINTING_PHOTO,
    getOneGoodImg: API_ROUTES.GET_ONE_GOOD_PAINTING_IMG,
    navigateOnreload: APP_ROUTES.CHOOSE_SIZE_PAINTING,
    photoStyle: APP_ROUTES.PAINTING_PHOTO_STYLE,
    uploadPhoto: APP_ROUTES.UPLOAD_PAINTING_PHOTO,
  },
  [ProductTypes.EMBROIDERY]: {
    uploadApi: API_ROUTES.UPLOAD_EMBROIDERY_PHOTO,
    getOneGoodImg: API_ROUTES.GET_ONE_GOOD_EMBROIDERY_IMG,
    navigateOnreload: APP_ROUTES.CHOOSE_SIZE_EMBROIDERY,
    photoStyle: APP_ROUTES.EMBROIDERY_PHOTO_STYLE,
    uploadPhoto: APP_ROUTES.UPLOAD_EMBROIDERY_PHOTO,
  },
  [ProductTypes.DOT_PAINTING]: {
    uploadApi: API_ROUTES.UPLOAD_DOT_PAINTING_PHOTO,
    getOneGoodImg: API_ROUTES.GET_ONE_GOOD_DOT_PAINTING_IMG,
    navigateOnreload: APP_ROUTES.CHOOSE_SIZE_DOT_PAINTING,
    photoStyle: APP_ROUTES.DOT_PAINTING_PHOTO_STYLE,
    uploadPhoto: APP_ROUTES.UPLOAD_DOT_PAINTING_PHOTO,
  },
};

const responseFields = ['paint1', 'mosaic1', 'embroidery1', 'dotpaint1'];

const EditorPage = () => {
  const location = useLocation();
  const props = location.state;
  const {
    setEditedPhotosArr,
    setChoosenPhoto,
    code,
    styleVariant,
    size,
    productType,
    aspect,
  } = useContext(PhotoContext);
  const { isMosaic } = useProductType(productType);

  const navigate = useNavigate();

  useEffect(() => {
    navigateOnReload();
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const navigateOnReload = () => {
    window.onbeforeunload = () => {
      window.setTimeout(function () {
        window.location.replace(
          code
            ? APP_ROUTES.INSTRUCTION
            : apiRoutesMap[productType].navigateOnreload,
        );
      }, 0);
    };
  };

  const navigateNext = () => {
    const navigationRoute = props?.navigateToEmail
      ? APP_ROUTES.PHOTO_STYLES_INSTRUCTION
      : apiRoutesMap[productType].photoStyle;

    navigate(navigationRoute);
  };

  const navigateBack = () => {
    const navigationRoute = props?.navigateToEmail
      ? APP_ROUTES.INSTRUCTION
      : apiRoutesMap[productType].uploadPhoto;
    navigate(navigationRoute);
  };
  const sendPhoto = async (base: any, imagePath?: string) => {
    if (!props?.navigateToEmail && base) {
      const res = await api({
        URL: apiRoutesMap[productType].uploadApi,
        options: { method: 'POST', body: { image: base, size } },
      });
      if (res?.['black']) {
        setEditedPhotosArr(res);
        setChoosenPhoto(STYLES_ARR[0].src);
        navigateNext();
      }
    } else {
      try {
        const res = await api({
          URL: apiRoutesMap[productType].getOneGoodImg,
          options: {
            method: 'POST',
            body: {
              image: base,
              image_path: imagePath,
              type_mosaic: styleVariant ? styleVariant : 1,
              type_embroidery: styleVariant ? styleVariant : 1,
              code: code,
              ...(isMosaic && { size }),
            },
          },
        });
        responseFields.forEach((field) => {
          if (res[field]) {
            setEditedPhotosArr(res);
            setChoosenPhoto(res[field]);
            navigateNext();
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className={styles.pageContent}>
      <PhotoEditor
        sendPhoto={sendPhoto}
        navigateBack={navigateBack}
        aspect={aspect as number}
      />
    </div>
  );
};

export default EditorPage;
