import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { APP_ROUTES } from '../../constants/appRoutes';

import instruction1 from '../../assets/instruction1.png';
import instruction2 from '../../assets/instruction2.png';
import instruction3 from '../../assets/instruction3.png';
import instruction4 from '../../assets/instruction4.png';
import instruction5 from '../../assets/instruction5.svg';
import dotPaintingInstruction1 from '../../assets/dotPaintingInstruction1.png';
import dotPaintingInstruction2 from '../../assets/dotPaintingInstruction2.png';
import dotPaintingInstruction3 from '../../assets/dotPaintingInstruction3.png';
import dotPaintingInstruction4 from '../../assets/dotPaintingInstruction4.png';
import dotPaintingInstruction5 from '../../assets/dotPaintingInstruction5.png';
import embroideryInstruction1 from '../../assets/embroideryInstruction1.png';
import embroideryInstruction2 from '../../assets/embroideryInstruction2.png';
import embroideryInstruction3 from '../../assets/embroideryInstruction3.png';
import embroideryInstruction4 from '../../assets/embroideryInstruction4.png';
import embroideryInstruction5 from '../../assets/embroideryInstruction5.png';

import styles from './styles.module.scss';
import Button from '../../components/Button';
import { PhotoContext } from '../../context/PhotoProvider';
import { ProductTypes } from '../../types/enums';

const dataByTypeMap = {
  [ProductTypes.MOSAIC]: {
    blocks: [
      {
        src: instruction1,
        text: 'theInstrutionHasSectors',
      },
      {
        src: instruction2,
        text: 'theNumbersShow',
      },
      {
        src: instruction3,
        text: 'forConvinience',
      },
      {
        src: instruction4,
        text: 'youCanMark',
      },
      {
        src: instruction5,
        text: 'toRemoveTheMark',
      },
    ],
    backRoute: APP_ROUTES.INTERACTIVE_INSTRUCTION,
    typography: {
      paragraph: 'toBuildTogether',
      control: 'backToAssembling',
    },
  },
  [ProductTypes.EMBROIDERY]: {
    blocks: [
      {
        src: embroideryInstruction1,
        text: 'embroideryInstruction1',
      },
      {
        src: embroideryInstruction2,
        text: 'embroideryInstruction2',
      },
      {
        src: embroideryInstruction3,
        text: 'forConvinience',
      },
      {
        src: embroideryInstruction4,
        text: 'youCanMarkEmbroidery',
      },
      {
        src: embroideryInstruction5,
        text: 'toRemoveTheMark',
      },
    ],
    backRoute: APP_ROUTES.EMBROIDERY_INTERACTIVE_INSTRUCTION,
    typography: {
      paragraph: 'toBuildTogether',
      control: 'backToAssembling',
    },
  },
  [ProductTypes.DOT_PAINTING]: {
    blocks: [
      {
        src: dotPaintingInstruction1,
        text: 'dotPaintingInstruction1',
      },
      {
        src: dotPaintingInstruction2,
        text: 'dotPaintingInstruction2',
      },
      {
        src: dotPaintingInstruction3,
        text: 'dotPaintingInstruction3',
      },
      {
        src: dotPaintingInstruction4,
        text: 'youCanMarkDotPainting',
      },
      {
        src: dotPaintingInstruction5,
        text: 'dotPaintingInstruction5',
      },
    ],
    backRoute: APP_ROUTES.DOT_PAINTING_INTERACTIVE_INSTRUCTION,
    typography: {
      paragraph: 'toBuildTogetherDotPainting',
      control: 'backToPainting',
    },
  },
  [ProductTypes.PAINTING]: {
    blocks: [
      {
        src: instruction1,
        text: 'theInstrutionHasSectors',
      },
      {
        src: instruction2,
        text: 'theNumbersShow',
      },
      {
        src: instruction3,
        text: 'forConvinience',
      },
      {
        src: instruction4,
        text: 'youCanMark',
      },
      {
        src: instruction5,
        text: 'toRemoveTheMark',
      },
    ],
    backRoute: APP_ROUTES.INTERACTIVE_INSTRUCTION,
    typography: {
      paragraph: 'toBuildTogether',
      control: 'backToAssembling',
    },
  },
};

const HowToUseInstruction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { order, productType } = useContext(PhotoContext);

  const navigateBack = () => {
    navigate(dataByTypeMap[productType].backRoute + order);
  };

  return (
    <div className={styles.wrapper}>
      <h1>{t('howToUseInstruction')}</h1>
      {dataByTypeMap[productType].blocks.map((el, i) => (
        <div key={i} className={styles.instructionBlock}>
          <div className={styles[`inner_${i}`]}>
            <img src={el.src} alt="instruction-photo" />
          </div>
          <p className={styles.description}>{<Trans i18nKey={el.text} />}</p>
        </div>
      ))}
      <p className={styles.toBuild}>
        <Trans i18nKey={dataByTypeMap[productType].typography.paragraph} />
      </p>
      <Button
        variant="tertiary"
        onClick={navigateBack}
        text={t(dataByTypeMap[productType].typography.control)}
      />
    </div>
  );
};

export default HowToUseInstruction;
