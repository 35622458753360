import React, { useState, useEffect } from 'react';
import ListItem from './components/ListItem';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { API_ROUTES } from '../../constants/api_routes';

import styles from './style.module.scss';
import Surface from '../../components/Surface';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';

const FAQ = () => {
  const [expandItem, setExpandItem] = useState<number | null>(null);
  const [FAQData, setFAQData] = useState([]);
  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language;
  const navigate = useNavigate();

  useEffect(() => {
    const getFAQWords = async () => {
      const FAQWords = await api({
        URL: API_ROUTES.LANG(selectedLanguage),
      });
      if (FAQWords) {
        setFAQData(FAQWords);
      }
    };

    getFAQWords();
  }, [selectedLanguage]);

  const navigateBack = () => {
    navigate(APP_ROUTES.MAIN_PAGE);
  };

  return (
    <div className={styles.wrapper}>
      <Surface radius="21px">
        <div className={styles.faqPage}>
          <h1>{t('FAQ')} / FAQ</h1>
          <ul className={styles.faqList}>
            {FAQData?.length > 0 &&
              FAQData.map((item: any, key) => (
                <ListItem
                  id={key}
                  question={item.question}
                  answer={item.answer}
                  isExpand={expandItem === key}
                  setExpandItem={setExpandItem}
                  key={key}
                />
              ))}
          </ul>
        </div>
      </Surface>
      <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
    </div>
  );
};

export default FAQ;
