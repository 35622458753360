import React from 'react';

import styles from './styles.module.scss';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';

interface ICheckboxProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  labelLink: boolean;
}

const Checkbox = ({
  checked,
  setChecked,
  label,
  labelLink,
}: ICheckboxProps) => {
  const navigate = useNavigate();

  const navigateToPersonalData = () => {
    navigate(APP_ROUTES.POLICY);
  };
  return (
    <div className={styles.checkboxContainer}>
      <input
        className={styles.checkbox}
        type="checkbox"
        id="checkbox"
        name="checkbox"
        checked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
      />
      <label className={styles.label} htmlFor="checkbox">
        {label}{' '}
        {labelLink && (
          <span
            className={styles.personalData}
            onClick={navigateToPersonalData}
          >
            {t('personalData')}
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
