import React, { useState, ReactNode, useContext } from 'react';
import '../../App.scss';
import Surface from '../Surface';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Email from '../Email';
import Loader from '../Loader';
import { ReactComponent as LoaderIcon } from '../../assets/loaderIcon.svg';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { API_ROUTES } from '../../constants/api_routes';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { PhotoContext } from '../../context/PhotoProvider';
// import { getPdfUrl } from '../../services/getPdfUrl';
import { APP_ROUTES } from '../../constants/appRoutes';
import { ProductTypes } from '../../types/enums';

interface IEnterEmailSurface {
  onBack: () => void;
  text: ReactNode;
  subscribe?: boolean;
}

const apiMap = {
  [ProductTypes.MOSAIC]: API_ROUTES.PDF,
  [ProductTypes.PAINTING]: API_ROUTES.PAINTING_PDF,
  [ProductTypes.EMBROIDERY]: API_ROUTES.EMBROIDERY_PDF,
  [ProductTypes.DOT_PAINTING]: API_ROUTES.DOT_PAINTING_PDF,
};

const EnterEmailSurface = ({ onBack, text, subscribe }: IEnterEmailSurface) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
  const [emailError, setEmailError] = useState('');
  const { state } = useLocation();
  const [loaderVisible, setLoaderVisible] = useState(false);

  const {
    setPdfUrl,
    code,
    styleVariant,
    choosenPhoto,
    setOrder,
    productType,
    size,
  } = useContext(PhotoContext);

  const emailCheck = async () => {
    setLoaderVisible(true);

    const response = await api({
      URL: API_ROUTES.SUBSCRIBE,
      options: {
        method: 'POST',
        body: {
          email,
          list: state.type,
        },
      },
    });
    if (response) {
      navigate(APP_ROUTES.SUBSCRIBE_SUCCESS);
    }
  };
  const emailCheckCode = async () => {
    setLoaderVisible(true);
    const res = await api({
      URL: apiMap[productType],
      options: {
        method: 'POST',
        body: {
          image: choosenPhoto,
          type_mosaic: styleVariant ? styleVariant : 1,
          type_paint: styleVariant ? styleVariant : 1,
          type_embroidery: styleVariant ? styleVariant : 1,
          code: code,
          email: email,
          size: size,
        },
      },
    });
    if (res?.pdf_url && (productType === ProductTypes.PAINTING || res.order)) {
      setLoaderVisible(false);
      // const pdfUrl = await getPdfUrl(res?.pdf_url);
      if (productType !== ProductTypes.PAINTING) setOrder(res.order);
      setPdfUrl(res.pdf_url);
      const props = {
        email: email,
      };
      navigate(APP_ROUTES.INSTRUCTION_LINK, { state: props });
    } else if (res && res.error) {
      setLoaderVisible(false);
      setEmailError(res.error);
    } else {
      const wrongMessage = t('smthWentWrong');
      setLoaderVisible(false);
      setEmailError(wrongMessage);
    }
  };

  const delay = code ? 60 : 5;

  return (
    <div className={styles.emailWrapper}>
      {loaderVisible ? (
        <Loader delay={delay} />
      ) : (
        <Surface>
          <div className={styles.emailContainer}>
            {subscribe && (
              <div className={styles.subscribe}>
                <p className={styles.text}>{t('productInDevelopment')}</p>
                <LoaderIcon />
              </div>
            )}
            <>
              <p className={styles.text}>{text}</p>
              <p className={styles.enterEmail}>{t('enterYourEmail')}</p>

              <Email
                email={email}
                setEmail={setEmail}
                emailError={emailError}
                setEmailError={setEmailError}
              />
              <Checkbox
                checked={checked}
                setChecked={setChecked}
                labelLink
                label={t('IConsentToTheProcessingOfMyPersonalData.')}
              />
              <div className={styles.buttons}>
                <Button variant="tertiary" text={t('back')} onClick={onBack} />
                <Button
                  text={t('send')}
                  variant="secondary"
                  onClick={subscribe ? emailCheck : emailCheckCode}
                  disabled={!checked || emailError !== '' || email === ''}
                />
              </div>
            </>
          </div>
        </Surface>
      )}
    </div>
  );
};

export default EnterEmailSurface;
