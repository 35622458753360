import React, { useState } from 'react';
import '../../App.scss';

import styles from './styles.module.scss';
// import LanguagesList from '../LanguagesList';
import { useTranslation } from 'react-i18next';
import { languagesList } from '../../constants/languages';
import polygon from '../../assets/icons/polygon.svg';
import { APP_ROUTES } from '../../constants/appRoutes';
import { Link } from 'react-router-dom';

interface IBurgerMenu {
  onLang: (lang: string) => void;
  onFaq: () => void;
}

const BurgerMenu = ({ onLang, onFaq }: IBurgerMenu) => {
  const lang = localStorage.getItem('lang');

  const language = languagesList.find((l) => l.langName === lang)?.name;
  const { t } = useTranslation();
  const [openLang, setOpenLang] = useState(false);

  const showLanguageList = () => {
    setOpenLang(!openLang);
  };

  return (
    <div className={styles.burger}>
      <div className={styles.buttonsContainer}>
        <Link to={APP_ROUTES.FAQ} className={styles.faq} onClick={onFaq}>
          {t('FAQ')}
        </Link>
        <div onClick={showLanguageList} className={styles.languageButton}>
          <span className={styles.faq}>{language ? language : 'Русский'}</span>
          <img
            src={polygon}
            alt="polygon"
            className={openLang ? styles.polygon : ''}
          />
        </div>
        <div className={styles.dropdown}>
          {openLang
            ? languagesList.map(
                (l, i) =>
                  l.name !== language && (
                    <span
                      onClick={() => onLang(l.langName)}
                      className={styles.faq}
                      key={i}
                    >
                      {l.name}
                    </span>
                  ),
              )
            : ''}
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
