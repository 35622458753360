import React, { FC, ReactNode } from 'react';
import Button from '../Button';
import '../../App.scss';
import { colorMap, Colors } from '../../constants/colorMap';
import { clsx } from 'clsx';

import styles from './styles.module.scss';

interface IBlockProps {
  text: string | ReactNode;
  description?: string | ReactNode;
  buttonText: string;
  variant?: 'primary' | 'primary-color' | 'secondary' | 'tertiary';
  buttonAction: (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => void | Promise<void> | object;
  image?: any;
  background?: any;
  backgroundMobile?: any;
  alignment?: 'vertical' | 'horizontal';
  color?: Colors;
  main?: boolean;
  isLongText?: boolean;
}

const Block: FC<IBlockProps> = ({
  text,
  description,
  buttonText,
  buttonAction,
  variant = 'primary',
  color = 'purple',
  alignment = 'horizontal',
  background,
  backgroundMobile,
  image,
  main,
  isLongText,
}) => {
  const isDesktop = window.innerWidth > 600;
  return (
    <div
      className={styles[`block${main ? '_main' : ''}`]}
      style={
        color && {
          background: `${colorMap[color]}1) ${
            background ? (isDesktop ? background : backgroundMobile) : ''
          } no-repeat center`,
        }
      }
    >
      <div className={styles[alignment]}>
        <div className={styles[alignment]}>
          <p
            className={clsx(
              styles.text,
              isLongText && !isDesktop && styles.longTextMobile,
            )}
          >
            {text}
          </p>
          <p className={styles.description}>{description}</p>
        </div>
        <Button text={buttonText} onClick={buttonAction} variant={variant} />
      </div>
      {image && <img src={image} className={styles.image} alt="block image" />}
    </div>
  );
};

export default Block;
