import Compressor from 'compressorjs';
export const uploadImg = async (
  e: React.ChangeEvent<HTMLInputElement>,
  setPhoto: React.Dispatch<React.SetStateAction<any | null>>,
) => {
  if (e.target.files && e.target.files.length === 1) {
    const file = e.target.files[0];
    const imageDataUrl = await readFile(file);
    setPhoto(imageDataUrl);
  }
};

const readFile = (file: File) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      getNormalizedFile(file)
        .then((normalizedFile) => reader.readAsDataURL(normalizedFile as Blob))
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};
export const getNormalizedFile = (file: File) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      success(normalizedFile) {
        resolve(normalizedFile);
      },
      error(error) {
        reject(error);
      },
    });
  });
};
