import React, { createContext, useState, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { MatrixType } from '../constants/diamonds';
import { usePrevious } from '../hooks/usePrevious';
import { ProductTypes } from '../types/enums';

interface IPhotoContext {
  photo: string | null | any;
  setPhoto: React.Dispatch<React.SetStateAction<any>>;
  editedPhotosArr: any;
  setEditedPhotosArr: any;
  aspect: number;
  setAspect: React.Dispatch<React.SetStateAction<number>>;
  size: string;
  setSize: React.Dispatch<React.SetStateAction<string>>;
  choosenPhoto: string;
  setChoosenPhoto: React.Dispatch<React.SetStateAction<string>>;
  code: string | null;
  setCode: React.Dispatch<React.SetStateAction<string | null>>;
  styleVariant: number;
  setStyleVariant: React.Dispatch<React.SetStateAction<number>>;
  pdfUrl: string;
  setPdfUrl: React.Dispatch<React.SetStateAction<string>>;
  matrix: MatrixType[][];
  setMatrix: React.Dispatch<React.SetStateAction<MatrixType[][]>>;
  order: string;
  setOrder: React.Dispatch<React.SetStateAction<string>>;
  productType: ProductTypes;
  setProductType: React.Dispatch<React.SetStateAction<ProductTypes>>;
  prevLocation?: string;
}

const defaultValue: IPhotoContext = {
  photo: null,
  setPhoto: () => {
    return null;
  },
  editedPhotosArr: {},
  setEditedPhotosArr: () => {
    return [];
  },
  choosenPhoto: '',
  setChoosenPhoto: () => {
    return '';
  },
  code: null,
  setCode: () => {
    return null;
  },
  size: '',
  setSize: () => {
    return '';
  },
  aspect: 1,
  setAspect: () => {
    return 1;
  },
  styleVariant: 0,
  setStyleVariant: () => {
    return 0;
  },
  pdfUrl: '',
  setPdfUrl: () => {
    return '';
  },
  matrix: [[]],
  setMatrix: () => {
    return [[]];
  },
  order: '',
  setOrder: () => {
    return '';
  },
  productType: ProductTypes.MOSAIC,
  setProductType: () => {
    return ProductTypes.MOSAIC;
  },
};

export const PhotoContext = createContext<IPhotoContext>(defaultValue);

interface IPhotoProviderProps {
  children: ReactNode;
}

const PhotoProvider = ({ children }: IPhotoProviderProps) => {
  const [photo, setPhoto] = useState<any>(null);
  const [editedPhotosArr, setEditedPhotosArr] = useState<any>({});
  const [choosenPhoto, setChoosenPhoto] = useState<string>('');
  const [code, setCode] = useState<string | null>(null);
  const [styleVariant, setStyleVariant] = useState<number>(0);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [aspect, setAspect] = useState<number>(1);
  const [matrix, setMatrix] = useState<MatrixType[][]>([[]]);
  const [order, setOrder] = useState<string>('');
  const [productType, setProductType] = useState<ProductTypes>(
    ProductTypes.MOSAIC,
  );
  const location = useLocation();
  const prevLocation = usePrevious(location.pathname);

  return (
    <PhotoContext.Provider
      value={{
        pdfUrl,
        setPdfUrl,
        photo,
        setPhoto,
        size,
        setSize,
        aspect,
        setAspect,
        editedPhotosArr,
        setEditedPhotosArr,
        choosenPhoto,
        setChoosenPhoto,
        code,
        setCode,
        styleVariant,
        setStyleVariant,
        matrix,
        setMatrix,
        order,
        setOrder,
        productType,
        setProductType,
        prevLocation,
      }}
    >
      {children}
    </PhotoContext.Provider>
  );
};

export default PhotoProvider;
