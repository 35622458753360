export const APP_ROUTES = {
  MAIN_PAGE: '/',
  CATALOGUE: '/catalogue',
  EDITOR: '/editor',
  DIAMOND_PHOTO_MOSAIC: '/diamond-photo-mosaic',
  CHOOSE_STYLE: '/choose-style',
  CHOOSE_SIZE_DIAMOND_PHOTO_MOSAIC: '/diamond-photo-mosaic-size',
  CHOOSE_SIZE_PAINTING: '/painting-size',
  CHOOSE_SIZE_EMBROIDERY: '/embroidery-size',
  CHOOSE_SIZE_DOT_PAINTING: '/dot-painting-size',
  HOW_TO_CHOOSE_PHOTO: '/how-to-choose-photo',
  HOW_TO_CHOOSE_DOT_PAINTING_PHOTO: '/how-to-choose-dot-painting-photo',
  HOW_TO_CHOOSE_EMBROIDERY_PHOTO: '/how-to-choose-embroidery-photo',
  FAQ: '/faq',
  UPLOAD_PHOTO: '/upload-photo',
  UPLOAD_PAINTING_PHOTO: '/upload-painting-photo',
  UPLOAD_DOT_PAINTING_PHOTO: '/upload-dot-painting-photo',
  UPLOAD_EMBROIDERY_PHOTO: '/upload-embroidery-photo',
  PHOTO_STYLE: '/photo-style',
  PAINTING_PHOTO_STYLE: '/painting-photo-style',
  EMBROIDERY_PHOTO_STYLE: '/embroidery-photo-style',
  DOT_PAINTING_PHOTO_STYLE: '/dot-painting-photo-style',
  INSTRUCTION: '/instruction',
  PAINTING_INSTRUCTION: '/painting-instruction',
  EMBROIDERY_INSTRUCTION: '/embroidery-instruction',
  DOT_PAINTING_INSTRUCTION: '/dot-painting-instruction',
  INTERACTIVE_INSTRUCTION: '/interactive-instruction/:id',
  EMBROIDERY_INTERACTIVE_INSTRUCTION: '/embroidery-interactive-instruction/:id',
  DOT_PAINTING_INTERACTIVE_INSTRUCTION:
    '/dot-painting-interactive-instruction/:id',
  HOW_TO_USE_INSTRUCTION: '/how-to-use-instruction',
  HOW_TO_USE_EMBROIDERY_INSTRUCTION: '/how-to-use-embroidery-instruction',
  HOW_TO_USE_DOT_PAINTING_INSTRUCTION: '/how-to-use-dot-painting-instruction',
  PURCHASE: '/buy',
  PURCHASE_PAINTING: '/buy-painting',
  PURCHASE_EMBROIDERY: '/buy-embroidery',
  PURCHASE_DOT_PAINTING: '/buy-dot-painting',
  NOT_FOUND_PAGE: '*',
  ENTER_EMAIL: '/enter-email',
  PHOTO_STYLES_INSTRUCTION: '/photo-styles-instruction',
  INSTRUCTION_LINK: '/instruction-link',
  POLICY: '/policy',
  PRIZE_DRAW: '/prize_draw',
  UPLOAD_PHOTO_WITH_CODE: '/upload-photo-with-code',
  UPLOAD_PAINTING_PHOTO_WITH_CODE: '/upload-painting-photo-with-code',
  UPLOAD_EMBROIDERY_PHOTO_WITH_CODE: '/upload-embroidery-photo-with-code',
  UPLOAD_DOT_PAINTING_PHOTO_WITH_CODE: '/upload-dot-painting-photo-with-code',
  VIDEO_TUTORIAL: '/video-instructions',
  VIDEO_TUTORIAL_PAINTING: '/video-instructions-painting',
  VIDEO_TUTORIAL_EMBROIDERY: '/video-instructions-embroidery',
  VIDEO_TUTORIAL_DOT_PAINTING: '/video-instructions-dot-painting',
  SUBSCRIBE: '/subscribe',
  SUBSCRIBE_SUCCESS: '/subscribe-success',
  PAINTING: '/painting',
  EMBROIDERY: '/embroidery',
  PAINTING_TUTORIAL: '/painting-tutorial',
  DOT_PAINTING: '/dot-painting',
};
