import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import { APP_ROUTES } from '../../constants/appRoutes';
import styles from './styles.module.scss';
import '../../App.scss';
import Surface from '../../components/Surface';

import paintingInstruction1 from '../../assets/paintingInstruction1.png';
import paintingInstruction2 from '../../assets/paintingInstruction2.png';
import paintingInstruction3 from '../../assets/paintingInstruction3.png';
import paintingInstruction4 from '../../assets/paintingInstruction4.png';

const contentData = [
  {
    text: 'Скачайте сгенерированный PDF-файл, отправленный на почту.',
  },
  {
    text: 'Распечатайте файл на самоклеющейся бумаги из набора.',
  },
  { text: 'Обрежьте по контуру картины поля выделенные черной линией.' },
  {
    text: 'Аккуратно, не заменная лист, уберите верхнюю липкую полосу с распечатанной картины. Вам нужно с первого раза ровно приклеить картину. Старайтесь не оставлять пузырей воздуха и заломов.',
    imgSrc: paintingInstruction1,
  },
  {
    text: 'Приклеивайте от одной стороны к другой, постепенно убирая защитные полосы.',
    imgSrc: paintingInstruction2,
  },
  {
    text: 'Приклейте картину полностью, разглаживая бумагу рукой, чтобы избежать пузырей и отслоек. Тоже самое повторите с второй частью картины, если у вас размер 30*40.',
    imgSrc: paintingInstruction3,
  },
  {
    text: 'Если на распечатанном листе плохо видно цифры, то вы можете открыть PDF-инструкцию сразу на сайте или скачать отправленную на почту, для того, чтобы приблизить её с телефона.',
  },
  {
    text: 'Поздравляем! Вы успешно подготовили будущую картину и можете приступать к творчеству с набором Lakarti!',
    imgSrc: paintingInstruction4,
  },
];

const PaintingInstruction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(APP_ROUTES.INSTRUCTION_LINK);
  };

  const navigateToVideoTutorial = () => {
    navigate(APP_ROUTES.VIDEO_TUTORIAL_PAINTING, {
      state: { isBackToPaintingInstruction: true },
    });
  };

  return (
    <div className={styles.contentContainer}>
      <Surface radius={'20px'}>
        <div className={styles.block}>
          <h2 className={styles.title}>{t('paintingInstructionTitle')}</h2>
          {contentData.map((item, i) => {
            return (
              <div className={styles.customSurface} key={i}>
                <div className={styles.textWrapper}>
                  <p className={styles.text} style={{ marginRight: '6px' }}>{`${
                    i + 1
                  }.`}</p>
                  <p className={styles.text}>{item.text}</p>
                </div>
                {item.imgSrc ? (
                  <img src={item.imgSrc} className={styles.image} />
                ) : null}
              </div>
            );
          })}
        </div>
      </Surface>
      <div className={styles.buttonContainer}>
        <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
        <Button
          variant="secondary"
          text={t('videoTutorial')}
          onClick={navigateToVideoTutorial}
        />
      </div>
    </div>
  );
};

export default PaintingInstruction;
