import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';
import logo from '../../assets/icons/logo.svg';
import { ReactComponent as Polygon } from '../../assets/icons/polygon.svg';
import closedMenu from '../../assets/icons/closedMenu.svg';
import openedMenu from '../../assets/icons/openedMenu.svg';
import { useTranslation } from 'react-i18next';
import { languagesList } from '../../constants/languages';
import '../../App.scss';

import styles from './styles.module.scss';
import BurgerMenu from '../BurgerMenu';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const lang = localStorage.getItem('lang');

  const language = languagesList.find((l) => l.langName === lang)?.name;
  const { t } = useTranslation();

  const [openLang, setOpenLang] = useState(false);

  const showLanguageList = () => {
    setOpenLang(!openLang);
  };
  const { i18n } = useTranslation();

  const handleChangeLanguage = (language: string) => {
    const languageFormatted = language.toLowerCase().replace('-', '');
    i18n.changeLanguage(languageFormatted);
    localStorage.setItem('lang', languageFormatted);
    setOpenMenu(false);
  };

  const handleOnFaq = () => setOpenMenu(false);

  return (
    <div className={styles.header}>
      <Link to={APP_ROUTES.MAIN_PAGE}>
        <img className={styles.logo} src={logo} alt="La KARTI logo" />
      </Link>

      <div className={styles.buttonsContainer}>
        <Link to={APP_ROUTES.FAQ} className={styles.faq}>
          {t('FAQ')}
        </Link>
        <div
          onMouseEnter={() => setOpenLang(true)}
          onMouseLeave={() => setOpenLang(false)}
          onClick={showLanguageList}
          className={styles.languageButton}
        >
          <span className={styles.faq}>{language ? language : 'Русский'}</span>
          <Polygon className={styles.polygon} />
          <div className={styles.dropdown}>
            {openLang
              ? languagesList.map(
                  (l, i) =>
                    l.name !== language && (
                      <span
                        onClick={() => handleChangeLanguage(l.langName)}
                        className={styles.faq}
                        key={i}
                      >
                        {l.name}
                      </span>
                    ),
                )
              : ''}
          </div>
        </div>
      </div>
      <div className={styles.menu}>
        {openMenu ? (
          <div className={styles.menuWrapper}>
            <BurgerMenu onFaq={handleOnFaq} onLang={handleChangeLanguage} />
            <img
              className={styles.openedMenu}
              src={openedMenu}
              alt="opened menu"
              onClick={() => setOpenMenu(false)}
            />
          </div>
        ) : (
          <div className={styles.menuWrapper}>
            <img
              className={styles.closedMenu}
              src={closedMenu}
              alt="closed menu"
              onClick={() => setOpenMenu(true)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
