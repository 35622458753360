import React from 'react';

const EmbroiderySvg = ({ color }: { color: string }) => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5171 28.3619L28.6117 19.3594L35.7646 26.4396C36.5181 27.1856 36.5181 28.3947 35.7647 29.1406L29.3985 35.4424C28.6448 36.1883 27.4234 36.1881 26.6698 35.4424L19.5171 28.3619Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7649 9.56196C36.5184 8.81618 36.5183 7.60694 35.7649 6.86109L29.3986 0.55936C28.645 -0.186489 27.4236 -0.186417 26.6701 0.55936C17.9686 9.17302 9.26684 17.7865 0.565182 26.4C-0.188339 27.1457 -0.188412 28.3548 0.565073 29.1006L6.93133 35.4025C7.68481 36.1482 8.90641 36.1483 9.65972 35.4025C18.3614 26.7889 27.0631 18.1754 35.7649 9.56196Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.72355 16.6869L16.8181 7.68449L9.65954 0.598422C8.90639 -0.147355 7.68475 -0.147427 6.93134 0.598422L0.564937 6.90023C-0.18833 7.6459 -0.18833 8.85514 0.565046 9.60091L7.72355 16.6869Z"
        fill={color}
      />
    </svg>
  );
};

export default EmbroiderySvg;
