import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../App.scss';

import styles from './styles.module.scss';

interface IEmailProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailError: string;
  setEmailError: React.Dispatch<React.SetStateAction<string>>;
}

const Email = ({ email, setEmail, emailError, setEmailError }: IEmailProps) => {
  const { t } = useTranslation();

  const validateEmail = (e: string) => {
    setEmail(e);
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
    if (emailRegex.test(e)) {
      setEmailError('');
    } else {
      setEmailError(t('emailIsNotValid') || '');
    }
  };

  return (
    <div>
      <input
        value={email}
        onChange={(e) => validateEmail(e.target.value)}
        className={emailError ? styles.emailInputError : styles.emailInput}
      />
    </div>
  );
};

export default Email;
