import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';
import '../../App.scss';
// import styles from './styles.module.scss';
import EnterEmailSurface from '../../components/EnterEmailSurface';

const SubscribePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(APP_ROUTES.CATALOGUE);
  };

  return (
    <EnterEmailSurface
      onBack={navigateBack}
      text={t('leaveYourEmail')}
      subscribe
    />
  );
};

export default SubscribePage;
