import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { PhotoContext } from '../../../context/PhotoProvider';
import { ReactComponent as Arrow } from '../../../assets/icons/rightBlack.svg';
import SwiperCore from 'swiper';

import '../../../App.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import styles from './styles.module.scss';
import { APP_ROUTES } from '../../../constants/appRoutes';
import { useNavigate } from 'react-router-dom';
import Surface from '../../Surface';

interface IStylesContentProps {
  onClose: () => void;
  navigateToEmail?: boolean;
}

const StylesContent = ({ onClose, navigateToEmail }: IStylesContentProps) => {
  const { editedPhotosArr, choosenPhoto, setChoosenPhoto } =
    useContext(PhotoContext);
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const [clickedImg, setClickedImg] = useState<number>(1);

  const photosArray = Object.keys(editedPhotosArr);

  const navigateBack = () => {
    navigate(APP_ROUTES.UPLOAD_PHOTO_WITH_CODE);
  };

  const imgClassName = (index: number, exampleImg?: boolean) =>
    `body2 ${styles.smallImg} ${
      clickedImg === index ? styles.clickedImg : ''
    } ${exampleImg && clickedImg === index && styles.mainImg}`;

  const imgMainClassName = () =>
    `body2 ${styles.mainImg} ${navigateToEmail && styles.bigImg} } 
    `;
  swiperRef?.on('reachEnd', function () {
    setNextDisabled(true);
  });
  swiperRef?.on('reachBeginning', function () {
    setPrevDisabled(true);
  });

  const prevSlide = () => {
    swiperRef?.slidePrev();
    setNextDisabled(false);
  };
  const nextSlide = () => {
    swiperRef?.slideNext();
    setPrevDisabled(false);
  };

  const renderImg = () => {
    return (
      <Swiper
        slidesPerView="auto"
        navigation
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper: SwiperCore) => setSwiperRef(swiper)}
      >
        {photosArray.map((phot, index) => (
          <SwiperSlide className={styles.slide} key={phot}>
            <img
              src={editedPhotosArr[phot]}
              className={imgClassName(index + 1)}
              onClick={() => {
                setChoosenPhoto(editedPhotosArr[phot]);
                setClickedImg(index + 1);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  return (
    <div className={styles.wrapper}>
      {navigateToEmail ? (
        <h1 className={styles.header}>{t('variantsForStylingPicture')}</h1>
      ) : (
        <p className="body2">
          {t('afterPurchasingTheKitAdvancedPhotoGenerator')}
        </p>
      )}

      <img src={choosenPhoto} className={imgMainClassName()} />

      <div className="flexCenter">
        <div
          ref={navigationPrevRef}
          className={`${styles.prevButton} ${
            prevDisabled ? styles.disabled : ''
          }`}
          onClick={prevSlide}
        >
          <Arrow />
        </div>
        {navigateToEmail && (
          <Surface radius={window.innerWidth > 500 ? '32px' : '16px'}>
            <div className={styles.slider}>{renderImg()}</div>
          </Surface>
        )}
        <div
          ref={navigationNextRef}
          className={`${styles.nextButton} ${
            nextDisabled ? styles.disabled : ''
          }`}
          onClick={nextSlide}
        >
          <Arrow />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
        <Button
          variant="secondary"
          text={navigateToEmail ? t('next') : t('ok')}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default StylesContent;
