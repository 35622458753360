import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button } from '../../components';
import { APP_ROUTES } from '../../constants/appRoutes';

import '../../App.scss';

import styles from './styles.module.scss';
import api from '../../services/api';
import { API_ROUTES } from '../../constants/api_routes';
import Surface from '../../components/Surface';
import { ProductTypes } from '../../types/enums';
import { PhotoContext } from '../../context/PhotoProvider';
import { useLocation } from 'react-router-dom';

interface Video {
  id: number;
  url: string;
  title: string[];
  description: string;
}

const apiMap = {
  [ProductTypes.MOSAIC]: {
    videos: API_ROUTES.TUTORIAL_VIDEO,
    parentPage: APP_ROUTES.DIAMOND_PHOTO_MOSAIC,
  },
  [ProductTypes.PAINTING]: {
    videos: API_ROUTES.PAINTING_TUTORIAL_VIDEO,
    parentPage: APP_ROUTES.PAINTING,
  },
  [ProductTypes.EMBROIDERY]: {
    videos: API_ROUTES.EMBROIDERY_TUTORIAL_VIDEO,
    parentPage: APP_ROUTES.EMBROIDERY,
  },
  [ProductTypes.DOT_PAINTING]: {
    videos: API_ROUTES.DOT_PAINTING_TUTORIAL_VIDEO,
    parentPage: APP_ROUTES.DOT_PAINTING,
  },
};

const VideoTutorial = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [videos, setVideos] = useState<Video[]>([]);
  const { productType } = useContext(PhotoContext);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [initialProductType] = useState(productType);
  const { state } = useLocation();

  const navigateBack = () => {
    navigate(
      state?.isBackToPaintingInstruction
        ? APP_ROUTES.PAINTING_TUTORIAL
        : apiMap[productType].parentPage,
    );
  };

  useEffect(() => {
    api({
      URL: apiMap[productType].videos,
      options: {
        method: 'GET',
      },
    }).then((res) => {
      setVideos(res);
      setIsFirstFetch(false);
    });
  }, []);

  useEffect(() => {
    if (!isFirstFetch && initialProductType !== productType) {
      api({
        URL: apiMap[productType].videos,
        options: {
          method: 'GET',
        },
      }).then((res) => {
        setVideos(res);
      });
    }
  }, [productType, isFirstFetch]);

  const getUrl = (input: string) => {
    const match = input.match(/^video(-?\d+)_(-?\d+)$/);
    if (!match) {
      console.log('Invalid input format');
      return '';
    }

    const [, oid, id] = match;

    return `https://vkvideo.ru/video_ext.php?oid=${parseInt(
      oid,
      10,
    )}&id=${parseInt(id, 10)}`;
  };

  return (
    <div className={styles.purchaseContainer}>
      {videos.map((video: Video, index: number) => {
        return (
          <div key={index} className={styles.wrapper}>
            <Surface video>
              <h2>{video.title}</h2>
              <iframe
                src={getUrl(video.url)}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 0,
                }}
                allow="screen-wake-lock"
                allowFullScreen
              ></iframe>
            </Surface>
          </div>
        );
      })}
      <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
    </div>
  );
};

export default VideoTutorial;
