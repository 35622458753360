import React from 'react';
import { useNavigate } from 'react-router';
import { APP_ROUTES } from '../../constants/appRoutes';
import StylesContent from '../../components/PopUp/StylesContent';

import '../../App.scss';

const PhotoStylesInstruction = () => {
  const navigate = useNavigate();

  const navigateNext = () => {
    navigate(APP_ROUTES.ENTER_EMAIL);
  };
  return (
    <div className="contentCenter">
      <StylesContent onClose={navigateNext} navigateToEmail={true} />
    </div>
  );
};

export default PhotoStylesInstruction;
