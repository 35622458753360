export enum ProductTypes {
  MOSAIC = 'mosaic',
  PAINTING = 'painting',
  EMBROIDERY = 'embroidery',
  DOT_PAINTING = 'dot-painting',
}

export enum CompletionType {
  PARTIAL = 'partial',
  FULL = 'full',
  EMPTY = 'empty',
}
