import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import { APP_ROUTES } from '../../constants/appRoutes';
import { PhotoContext } from '../../context/PhotoProvider';
import styles from './styles.module.scss';
import '../../App.scss';
import Surface from '../../components/Surface';
import { ProductTypes } from '../../types/enums';

const dataByTypeMap = {
  [ProductTypes.MOSAIC]: {
    getInstructionLink: (pdfUrl: string) => pdfUrl,
    linkText: 'interactiveInstructions',
    linkButtonText: 'startAssembly',
    pdfLinkText: 'downloadPDFInstructions',
  },
  [ProductTypes.PAINTING]: {
    getInstructionLink: () => APP_ROUTES.PAINTING_TUTORIAL,
    linkText: 'paintingInstructionTitle',
    linkButtonText: 'open',
    pdfLinkText: 'downloadPDFPaintingInstructions',
  },
  [ProductTypes.EMBROIDERY]: {
    getInstructionLink: (pdfUrl: string) => pdfUrl,
    linkText: 'interactiveEmbroideryInstructions',
    linkButtonText: 'startAssembly',
    pdfLinkText: 'downloadPDFInstructions',
  },
  [ProductTypes.DOT_PAINTING]: {
    getInstructionLink: (pdfUrl: string) => pdfUrl,
    linkText: 'interactiveDotPaintingInstructions',
    linkButtonText: 'startAssembly',
    pdfLinkText: 'downloadPDFInstructions',
  },
};

const InstructionLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pdfUrl, productType } = useContext(PhotoContext);
  const location = useLocation();

  const email = location.state?.email;
  const navigateHome = () => {
    navigate(APP_ROUTES.MAIN_PAGE);
  };
  const navigateInteractiveInstruction = () => {
    const link = dataByTypeMap[productType].getInstructionLink(pdfUrl);
    if (link.startsWith('http://') || link.startsWith('https://')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
  };

  return (
    <div className={styles.contentContainer}>
      <div className={styles.customSurface}>
        <p>{t('instructionsOnTheEmail')}</p>
        <p className={styles.mail}>{email}</p>
      </div>
      <Surface radius={window.innerWidth > 780 ? '45px' : '35px'}>
        <div className={styles.block}>
          <p>{t(dataByTypeMap[productType].linkText)}</p>
          <Button
            variant="secondary"
            text={t(dataByTypeMap[productType].linkButtonText)}
            onClick={navigateInteractiveInstruction}
          />
        </div>
      </Surface>
      <Surface radius={window.innerWidth > 780 ? '45px' : '35px'}>
        <div className={styles.block}>
          <p>{t(dataByTypeMap[productType].pdfLinkText)}</p>
          <Link to={pdfUrl} target="_blank">
            <Button variant="secondary" text={t('pdfInstruction')} />
          </Link>
        </div>
      </Surface>
      <div className={styles.buttonContainer}>
        <Button variant="tertiary" text={t('home')} onClick={navigateHome} />
        <Link to="https://t.me/lakartiphoto" target="_blank">
          <Button
            borderColor="orange"
            prize
            variant="secondary"
            text={t('forThoseWhoBought')}
          />
        </Link>
      </div>
    </div>
  );
};

export default InstructionLink;
