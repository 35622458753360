import React from 'react';
import MainLayout from './containers/MainLayout';
import { I18nextProvider } from 'react-i18next';
import Router from './routes';
import PhotoProvider from './context/PhotoProvider';
import i18n from './translates';

import './App.scss';
import './theme/fonts.scss';

const App = () => {
  return (
    <PhotoProvider>
      <I18nextProvider i18n={i18n}>
        <MainLayout>
          <Router />
        </MainLayout>
      </I18nextProvider>
    </PhotoProvider>
  );
};

export default App;
