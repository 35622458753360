import React, { ReactNode } from 'react';
import '../../App.scss';

import styles from './styles.module.scss';
import { colorMap, Colors } from '../../constants/colorMap';

interface ISurface {
  color?: Colors;
  border?: boolean;
  background?: any;
  children: ReactNode;
  video?: boolean;
  radius?: string;
}

const Surface = ({
  children,
  color = 'purple',
  border,
  background,
  video,
  radius,
}: ISurface) => {
  return (
    <div
      className={
        video ? styles.video : styles[`surface${border ? '-border' : ''}`]
      }
      style={
        color
          ? {
              backgroundColor:
                color === 'white'
                  ? `${colorMap[color]}`
                  : `${colorMap[color]}0.1)`,
              backgroundImage: `${background ? background : ''}`,
              borderRadius: radius ? radius : '',
            }
          : {}
      }
    >
      {children}
    </div>
  );
};

export default Surface;
