import { ProductTypes } from '../types/enums';

export const useProductType = (productType: ProductTypes) => {
  const isMosaic = productType === ProductTypes.MOSAIC;
  const isDotPainting = productType === ProductTypes.DOT_PAINTING;
  const isEmbroidery = productType === ProductTypes.EMBROIDERY;
  const isPainting = productType === ProductTypes.PAINTING;

  return {
    isMosaic,
    isDotPainting,
    isEmbroidery,
    isPainting,
  };
};
