import React from 'react';

const DiamondSvg = ({ color }: { color: string }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.40996 18.0018L17.998 32.5898L32.5908 17.9971L18.0027 3.40904L3.40996 18.0018Z"
        fill={color}
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7278 30.7278L18.9141 32.5505L32.5505 18.9141L30.7278 30.7278Z"
        fill={color}
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.27186 30.7278L3.44922 18.9141L17.0856 32.5505L5.27186 30.7278Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4647 36.0001L18.0039 33.4609L20.543 36.0001H15.4647Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 20.5431L33.4608 18.004L36 15.4648V20.5431Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15.457L2.53915 17.9962L0 20.5353V15.457Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9962 2.53915L15.457 0H20.5353L17.9962 2.53915Z"
        fill={color}
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7278 5.27186L18.9141 3.44922L32.5505 17.0856L30.7278 5.27186Z"
        fill={color}
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.27186 5.27186L3.44922 17.0856L17.0856 3.44922L5.27186 5.27186Z"
        fill={color}
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14.5935L2.53822 17.1317L4.37148 5.22939L0 0.869141V14.5935Z"
        fill={color}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4061 36.0001L18.8682 33.4622L30.7704 31.6289L35.1304 36.0001H21.4061Z"
        fill={color}
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9997 21.4057L33.4612 18.8672L31.6279 30.7694L35.9997 35.1299V21.4057Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21.4119L2.54081 18.8711L4.37404 30.7733L0 35.1361V21.4119Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5983 35.9997L17.134 33.4641L5.23168 31.6309L0.874023 35.9997H14.5983Z"
        fill={color}
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4119 0L18.8711 2.54081L30.7733 4.37404L35.1361 0H21.4119Z"
        fill={color}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9997 14.5993L33.4641 17.1349L31.6309 5.23266L35.9997 0.875V14.5993Z"
        fill={color}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5934 0L17.1316 2.53818L5.22935 4.37144L0.869141 0H14.5934Z"
        fill={color}
      />
    </svg>
  );
};

export default DiamondSvg;
