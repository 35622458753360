const cyrillicToLatinMap: Record<string, string> = {
  О: 'O',
  Р: 'P',
  о: 'o',
  р: 'p',
};

export function transliterate(char: string) {
  return cyrillicToLatinMap[char] || char;
}
