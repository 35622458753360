import { useContext } from 'react';
import { APP_ROUTES } from '../constants/appRoutes';
import { PhotoContext } from '../context/PhotoProvider';

export const usePrevLocation = () => {
  const { prevLocation } = useContext(PhotoContext);
  const isPrevInstruction = prevLocation === APP_ROUTES.INSTRUCTION;
  const isPrevHowToChoosePhoto =
    prevLocation === APP_ROUTES.HOW_TO_CHOOSE_PHOTO;
  const isPrevMosaic = prevLocation === APP_ROUTES.DIAMOND_PHOTO_MOSAIC;

  return {
    isPrevInstruction,
    isPrevHowToChoosePhoto,
    isPrevMosaic,
  };
};
