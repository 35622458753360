import React, { ChangeEvent, MutableRefObject } from 'react';
import '../../App.scss';
import { transliterate } from '../../lib/cyrillicToLatic';

interface ICodeInputProps {
  index: number;
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  value: string;
  inputRefs: Array<MutableRefObject<HTMLInputElement | null>>;
  setErrorText: React.Dispatch<React.SetStateAction<string | null>>;
}

const CodeInput = ({
  index,
  values,
  setValues,
  value,
  inputRefs,
  setErrorText,
}: ICodeInputProps) => {
  const handleInputChange = (index: number, value: string | number) => {
    const alphanumericValue = value
      .toString()
      .replace(/[^a-zA-ZА-Яа-я0-9]/g, '');

    const newValues = [...values];
    newValues[index] = alphanumericValue;

    if (
      newValues.includes('О') ||
      newValues.includes('Р') ||
      newValues.includes('о') ||
      newValues.includes('р')
    ) {
      const translit = newValues.map((el) => transliterate(el));
      setValues(translit);
    } else {
      setValues(newValues);
    }
    if (alphanumericValue !== '') {
      const nextIndex = Math.min(index + 1, 8);
      inputRefs[nextIndex].current?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'ArrowLeft' && index > 0) {
      e.preventDefault();
      inputRefs[index - 1].current?.focus();
    } else if (e.key === 'ArrowRight' && index < inputRefs.length - 1) {
      e.preventDefault();
      inputRefs[index + 1].current?.focus();
    } else if (e.key === 'Backspace' && values[index] === '') {
      setErrorText(null);
      for (let i = index - 1; i >= 0; i--) {
        if (values[i] !== '') {
          e.preventDefault();
          inputRefs[i].current?.focus();
          break;
        }
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    handleInputChange(index, value);
  };

  return (
    <input
      ref={inputRefs[index]}
      value={value}
      onChange={(e) => handleChange(e, index)}
      onKeyDown={(e) => handleKeyDown(e, index)}
      maxLength={1}
      className="input"
    />
  );
};

export default CodeInput;
